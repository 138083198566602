import { Pie } from '@nivo/pie';
const PIE_OPTIONS = {

  enableArcLabels: false,
  height: 420,
  margin: { top: 50, right: 20, bottom: 80, left: 20 },
  activeOuterRadiusOffset: 8,
  borderWidth: 1,
  startAngle: -45,
  borderColor: { theme: 'background' },
  arcLinkLabelsTextColor: "#333333",
  arcLinkLabelsOffset: -7,
  arcLinkLabelsThickness: 2,
  arcLinkLabelsTextOffset: 4,
  arcLinkLabelsColor: { from: 'color' },
  arcLabelsRadiusOffset: 0.8,
  arcLabelsSkipAngle: 0,
  arcLinkLabelsStraightLength: 18,
  arcLinkLabelsDiagonalLength: 18
}
export const PieChart = (props) => {
  let options = JSON.parse(JSON.stringify(PIE_OPTIONS))
  return (
    <Pie
      width={props.width}
      data={props.data}
      arcLinkLabel={(data) => {
        let percentage = Number(data.value / props.total).toFixed(2);
        return `${data.label}, ${(Number((percentage)) * 100).toFixed(0)}%`
      }}
      {...options}
      colors={({ id, data }) => {
        return data.color;
      }}
      theme={{
        fontFamily: "'Exo 2', 'sans-serif'"
      }}
    />
  )
}