import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { connect } from "react-redux";
import { TwoLevelDropDown, ConfirmationBox, AppErrorBoundry, Input, Textarea, AutoCompleteSelect, LinkButton, DateSelect, DatePicker, AddButton, Button } from "../../components";
import { Dialog, DialogContent, DialogTitle, Grid, DialogActions } from '@mui/material';
import {
  createNewTask, getDepratmentList, getProrityList, getRepeationOccurList, updateTask, getDepartmentAuditee, getUser,
  getAllSourceInGroup, getAccountableList, isTaskCreated, getTask, isTaskUpdated, fetchDepartmentAuditee, getMappedOrgs,
  getDepartmentById, getPriorityById, getAccountableById, getSourceById, getAssigneeById, getSupervisors, getSupervisorById,
  getDueDateMatch, getTaskDueDate
} from "../../store";

const RepeationYearMatch = {
  "Daily": 1, "Weekly": 1, "Fortnightly": 1, "Monthly": 1, "Quarterly": 3,
  "Half-Yearly": 3, "Annualy": 3, "None": 0
}
const DueDateMatch = getDueDateMatch()

const FIELDS = [
  { field: 'name', label: 'Task Name', required: true },
  { field: 'desc', label: 'Task Name' },
  { field: 'department', label: 'Task Name', required: true },
  { field: 'priority', label: 'Task Name', required: true },
  // { field: 'accountable_id', label: 'Task Name', required: false },
  { field: 'assignee_id', label: 'Task Name', required: true },
  { field: 'supervisor_id', label: 'Task Name' },
  { field: 'asmt_id', label: 'Task Name', required: true },
  { field: 'start_date', label: 'Task Name', date: true },
  { field: 'end_date', label: 'Task Name', date: true },
  { field: 'due_date', label: 'Task Name', date: true },
  { field: 'evidence_expected', label: 'Task Name' },
  { field: 'external_links', label: 'Task Name' },
  { field: 'repetition_occurs' }
]
const getDefaultValues = (userOrg) => {
  let task = { org: { id: userOrg.id, label: userOrg.brandname } }
  FIELDS.forEach(_ => {
    task[_.field] = _.date ? null : '';
  });
  return task
}
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const TaskForm = (props) => {
  const { isTaskCreated, isTaskUpdated, user } = props
  const [task, setTask] = useState(props.task);
  const [error, setError] = useState({});
  const [repetition, setRepetition] = useState(props.repetition);
  const [evidence, setEvidence] = useState(props.evidence);
  const [refLinks, setRefLinks] = useState(props.refLinks);
  const [isModified, setIsModified] = useState(false)
  const prevUpdate = usePrevious({ isTaskUpdated })
  const prev = usePrevious({ isTaskCreated });
  useEffect(() => {
    if (
      (prev && !prev.isTaskCreated && isTaskCreated) ||
      (prevUpdate && !prevUpdate.isTaskUpdated && isTaskUpdated)
    ) {
      props.onCancel && props.onCancel()
    }
  }, [isTaskCreated, isTaskUpdated])
  const onValueChange = (e) => {
    const { name, value } = e.target;
    const newValue = { [name]: value };
    // console.log(name, value, e);
    // if (name === 'department') {
    //   props.fetchAssignee(value)
    // }
    setError((prev) => {
      return { ...prev, [name]: '' }
    })
    if ((name === 'department' || name === 'org')) {
      let org, dept;
      if (name === 'department' && task.org) {
        org = task.org.id; dept = value.id;
      } else if (name === 'org' && task.department) {
        org = value.id; dept = task.department.id;
      }
      if (org && dept) {
        props.fetchDepartmentAuditee(dept, org, user.ent_org_id);
      }
    }
    if (name === "due_date") {
      setRepetition(false)
    }
    setTask((prev) => {
      switch (name) {
        case 'repetition_occurs':
          newValue.end_date = RepeationYearMatch[value.id] ? moment().add(RepeationYearMatch[value.id], "year").toDate() : null;
          break;
        case 'priority':
          if (!repetition && value) {
            newValue.due_date = DueDateMatch[value.id] ? moment().add(DueDateMatch[value.id], "day").toDate() : null;
          }
          break;
        case 'department':
        case 'org':
          newValue.assignee_id = null
          break;
        case 'asmt_id':
          // console.log(newValue);
          break;
      }
      return { ...prev, ...newValue }
    })
  }
  const onSubmit = (e) => {
    e.stopPropagation();
    let valid = true, cError = {}, body = { ...task };
    for (let i = 0; i < FIELDS.length; i++) {
      let { required, field, date } = FIELDS[i];
      if (
        (field === 'repetition_occurs' && body[field] === 'None') ||
        (field === "asmt_id" ? required && !body[field] && !Boolean(body.asmt_id_text) : (required && !body[field]))
      ) {
        valid = false;
        cError[field] = 'required';
        continue;
      }
      if (field === 'asmt_id' && (body[field].group === 'Activity Types' || body[field].group === 'Custom' || Boolean(body[field].custom) || Boolean(body.asmt_id_text))) {
        body['activity_type'] = body[field] ? body[field].id : body.asmt_id_text;
        delete body[field];
      } else if (!date) {
        body[field] = (typeof body[field] === "object" && body[field] !== null) ? body[field].id : body[field];
      }
    }
    if (!valid) {
      setError({ ...cError })
      return;
    }
    body.start_date = (repetition ? moment(body.start_date) : moment()).startOf("day");
    body.end_date = (moment(repetition ? body.end_date : body.due_date)).startOf("day");
    if (body.due_date) {
      body.due_date = moment(body.due_date).startOf('day')
    } else {
      body.due_date = moment().add(DueDateMatch[body.priority], "day").startOf('day')
    }
    body.start_date = moment(body.start_date).toISOString();
    body.end_date = moment(body.end_date).toISOString();
    body.due_date = moment(body.due_date).toISOString();
    props.save(body);
  }
  const onClose = () => {
    let oldValue = getDefaultValues(props.user.ent_org), isMod = false;
    if (props.isEdit) {
      oldValue = props.task;
    }
    for (let i = 0; i < FIELDS.length; i++) {
      const { field } = FIELDS[i];
      if (task[field] !== oldValue[field]) {
        isMod = true;
        break
      }
    }
    if (isMod) {
      setIsModified(true)
    } else {
      props.onCancel()
    }
  }
  return (
    <Dialog
      open={props.open}
      onClose={(e) => e.code === 'Escape' && onClose()}
      className='dialog new-task-dialog'
      classes={{ paper: 'dialog-paper-root' }}>
      <DialogTitle className='h4 dialog-title' id="form-dialog-title">{props.isEdit ? 'Edit Task' : 'Create New Task'}</DialogTitle>
      <DialogContent className='dialog-content'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            <AppErrorBoundry>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <Input className='h41' required={true} name='name' error={error.name} value={task.name} maxlength={256} label='Task Name' placeholder='Task Name' onChange={onValueChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <AutoCompleteSelect
                        name='priority'
                        required={true}
                        value={task.priority}
                        label='Priority'
                        radioOption={true}
                        error={error.priority}
                        options={props.prorityList}
                        containerClass='select-group h41'
                        onChange={onValueChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Textarea className='h41' rows={4} name='desc' value={task.desc} maxlength={1024} label='Task Description' onChange={onValueChange} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <AutoCompleteSelect
                        required={true}
                        name='org'
                        containerClass='select-group h41'
                        value={task.org}
                        label='Task Org'
                        radioOption={true}
                        options={props.mappedOrgs}
                        error={error.org}
                        onChange={onValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <AutoCompleteSelect
                        required={true}
                        name='department'
                        containerClass='select-group h41'
                        value={task.department}
                        label='Department'
                        radioOption={true}
                        options={props.departmentList}
                        error={error.department}
                        onChange={onValueChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <AutoCompleteSelect
                        name='assignee_id'
                        required={true}
                        value={task.assignee_id}
                        label='Assignee'
                        radioOption={true}
                        disabled={!Boolean(task.department && task.org)}
                        options={task.department && task.org ? props.getDeptAssignee(task.department.id, task.org.id) : props.assigneeList}
                        onChange={onValueChange}
                        error={error.assignee_id}
                        containerClass='select-group h41'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TwoLevelDropDown
                        group={true}
                        required={true}
                        name='asmt_id' value={task.asmt_id} label='Source'
                        options={props.sources}
                        onChange={onValueChange}
                        error={error.asmt_id}
                        onTextChange={(text) => {
                          setTask((_) => ({ ..._, asmt_id_text: text }));
                          setError((_) => ({ ..._, asmt_id: null }))
                        }}
                        containerClass='select-group h41'
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <DateSelect
                        name='due_date'
                        disabled={props.isEdit ? false : repetition}
                        className='h41' label='Due Date' value={task.due_date || null} onChange={onValueChange} />
                    </Grid>
                    {
                      props.isEdit &&
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <AutoCompleteSelect
                          group={true}
                          radioOption={true}
                          required={true}
                          name='supervisor_id'
                          value={task.supervisor_id} label='Supervisor'
                          options={props.supervisors}
                          onChange={onValueChange}
                          error={error.supervisor_id}
                          containerClass='select-group h41'
                        />
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {
                    repetition ?
                      <div className='col input-group'>
                        <label>Select Repetition</label>
                        <div className='row space-btn input-repeat'>
                          <DatePicker name='start_date' value={task.start_date} label='Starting' className='row h41 repeat-date' onChange={onValueChange} />
                          <AutoCompleteSelect
                            className='row input-occur h41'
                            name='repetition_occurs'
                            value={task.repetition_occurs}
                            label='Occurs'
                            error={error.repetition_occurs}
                            options={props.repetitions}
                            onChange={onValueChange}
                          />
                          <DatePicker name='end_date' value={task.end_date} label='Until' className='row h41 repeat-date' onChange={onValueChange} />
                        </div>
                      </div>
                      :
                      <LinkButton label='+ Add Repetition' disabled={Boolean(task.due_date)} className='h42' onClick={() => {
                        setRepetition(true);
                        setTask(prev => {
                          return { ...prev, repetition_occurs: 'None', due_date: null, start_date: new Date(), end_date: null }
                        })
                      }} />
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {
                    evidence ?
                      <Textarea optional rows={4} name='evidence_expected' className='h42' value={task.evidence_expected} hint='These are the evidence that are expected to support claims of remediation & compliance.' label='Evidence(s) Expected' onChange={onValueChange} />
                      :
                      <LinkButton label='+ Add Evidence Required' className='h42' onClick={() => setEvidence(true)} />
                  }

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {
                    refLinks ?
                      <Textarea optional rows={4} name='external_links' className='h42' onChange={onValueChange} value={task.external_links} hint='Refer links provided below for additional information & understanding about this remediation & compliance task' label='Reference Link(s)' />
                      :
                      <LinkButton label='+ Add Reference Links' className='h42' onClick={() => setRefLinks(true)} />
                  }
                </Grid>
              </Grid>
            </AppErrorBoundry>

          </Grid>
          {/* <Grid item xs={12} sm={12} md={4} lg={3}>
            <div className='help-section'>
              <div className='col'>
                <div className='title'>Help Section</div>
                <p className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
            </div>
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className='row btn-row'>
          {
            props.isEdit ?
              <Button className='h4 btn-create-form' color='#1177CC' text='Update' onClick={onSubmit} />
              :
              <AddButton className='h4 btn-create-form' text='Create Task' onClick={onSubmit} />
          }
          <Button className='btn-cancel-form' text='Cancel' onClick={onClose} />
        </div>
      </DialogActions>
      {
        isModified &&
        <ConfirmationBox
          btnCancel={
            {
              text: 'Go Back', variant: 'text', className: 'h5 close', color: '#FFF',
              onClick: () => setIsModified(false)
            }
          }
          onOk={props.onCancel}
          btnOK='Yes, Okay'
          className='task-dialog-confirmation'
          titleClassName='title'
          subtitleClassName='subtitle'
          title='Task will not be saved!'
          subtitle='Everything that you have entered is NOT submitted. Exiting now will NOT save the Task.'
        />
      }
    </Dialog >
  )
}
const getTaskForEdit = (state, task, userOrg) => {
  return {
    ...task,
    due_date: getTaskDueDate(task),
    priority: getPriorityById(state, task.priority),
    department: getDepartmentById(state, task.department),
    assignee_id: getAssigneeById(state, task.assignee_id, task.department),
    accountable_id: getAccountableById(state, task.accountable_id),
    supervisor_id: getSupervisorById(state, task.supervisor_id),
    asmt_id: getSourceById(state, task.asmt_id || task.activity_type),
    org: task.assignee && task.assignee.ent_org ? { id: task.assignee.ent_org.id, label: task.assignee.ent_org.brandname } : { id: userOrg.id, label: userOrg.brandname }
  }
}
const getIdValue = (user) => {
  return { id: user.id, label: `${user.firstname} ${user.lastname}` }
}
const addToListIfNoFound = (item, list) => {
  let newList = [...list]
  let index = newList.findIndex((_) => _.id === item.id)
  if (index === -1) {
    newList.unshift({...item, label: item.label ? item.label: `${item.firstname} ${item.lastname || ''}`})
  }
  return newList
}
const mapStateToProps = (state, ownProps) => {
  const { isEdit, taskId } = ownProps
  const user = getUser(state);
  let task = isEdit ? getTaskForEdit(state, getTask(state, taskId), user.ent_org) : getDefaultValues(user.ent_org);
  let repetition = Boolean(isEdit && task && task.repetition_occurs);
  let assigneeList = getSupervisors(state);
  let supervisors = getSupervisors(state);
  let accountableList = getAccountableList(state);
  if (isEdit) {
    if (!task.assignee_id && task.assignee) {
      task.assignee_id = getIdValue(task.assignee);
      assigneeList = addToListIfNoFound(task.assignee, assigneeList);
    }
    if (!task.supervisor_id && task.supervisor) {
      task.supervisor_id = getIdValue(task.supervisor);
      supervisors = addToListIfNoFound(task.supervisor, supervisors);
    }
    if (!task.accountable_id && task.accountable) {
      task.accountable_id = getIdValue(task.accountable);
      accountableList = addToListIfNoFound(task.accountable, accountableList);
    }
  }
  return {
    user: user,
    task: task,
    evidence: Boolean(isEdit && task && task.evidence_expected),
    refLinks: Boolean(isEdit && task && task.external_links),
    repetition: repetition,
    prorityList: getProrityList(state),
    departmentList: getDepratmentList(state),
    sources: getAllSourceInGroup(state, false),
    mappedOrgs: getMappedOrgs(state, user.ent_org),
    assigneeList: assigneeList,
    accountableList: accountableList,
    supervisors: supervisors,
    repetitions: getRepeationOccurList(state),
    isTaskCreated: isTaskCreated(state),
    isTaskUpdated: isTaskUpdated(state),
    getDeptAssignee: (dept, orgId) => {
      return getDepartmentAuditee(state, dept, orgId)
    },
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const { isEdit, taskId } = ownProps
  return {
    save: (task) => {
      dispatch(isEdit ? updateTask(task, taskId) : createNewTask(task))
    },
    fetchDepartmentAuditee: (dept, orgId, currentUserOrg) => {
      dispatch(fetchDepartmentAuditee(dept, orgId, currentUserOrg))
    }
  }
}
export const CreateTaskForm = connect(mapStateToProps, mapDispatchToProps)(TaskForm)

