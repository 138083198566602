import React, { useEffect, useState } from 'react';
import { Autocomplete, Radio, ClickAwayListener } from '@mui/base';
import AdapterDateFns from '@mui/lab/AdapterMoment';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { styled } from '@mui/system';
import { PopperUnstyled } from '@mui/base';
import { LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import { AvatarCard } from "../Screen/screen.component";
import moment from "moment";

export const InputContainer = (props) => {
  const { id, hint, label, maxlength, error, value, className, labelClassName } = props;
  return (
    <div className={`col input-group ${className || ''}`}>
      {
        Boolean(label) && <label htmlFor={id} className={`h7 ${labelClassName || ''}`}>{label}</label>
      }
      {
        Boolean(hint) &&
        <div className='hint'>{hint}</div>
      }
      <div className={`row input-container ${Boolean(error) && 'error'}`}>
        {props.children}
      </div>
      {
        Boolean(maxlength) && !isNaN(Number(maxlength)) && Number(maxlength) > 0 &&
        <div className={`row full`}>
          <div className='h6 max-len'>{String(value || '').length} / {maxlength} Characters</div>
        </div>
      }
    </div>
  )
}
const getDateFormatValue = (value) => {
  return value ? moment(value).format('ddd, DD-MMM-YY') : '';
}

export const Input = (props) => {
  const { id, hint, label, placeholder, maxlength, className, error, ...rest } = props;
  return (
    <InputContainer error={error} hint={hint} className={className} id={id} label={label} maxlength={maxlength} value={rest.value}>
      <input
        id={id || ''}
        maxLength={maxlength}
        placeholder={label || placeholder}
        {...rest}
      />
    </InputContainer>
  )
}
export const Textarea = (props) => {
  const { id, hint, placeholder, label, maxlength, className, error, optional, ...rest } = props;
  return (
    <InputContainer hint={hint} className={className} id={id} error={error} label={label} maxlength={maxlength} value={rest.value}>
      <textarea
        id={id || ''}
        maxLength={maxlength}
        placeholder={label || placeholder}
        {...rest}
      />
      {
        Boolean(optional) &&
        <div className='h42 hint-optinal'>(Optional)</div>
      }
    </InputContainer>
  )
}
export const DateSelect = (props) => {
  const { id, label, className, placeholder, value, ...rest } = props
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="Custom input"
        className='h5'
        value={value}
        disabled={rest.disabled}
        onChange={(_moment) => {
          rest.onChange && rest.onChange({ target: { name: rest.name, value: _moment ? _moment.toDate() : '' } })
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => {
          return (
            <InputContainer className={`date ${className}`} label={label} placeholder={placeholder} error={rest.error}>
              <input id={`${id || ''}`} type='text' ref={inputRef} {...inputProps} value={getDateFormatValue(inputProps.value)} placeholder={placeholder || label} />
              {InputProps?.endAdornment}
            </InputContainer>
          )
        }}
      />
    </LocalizationProvider>
  )
}
export const DatePicker = (props) => {
  const { id, label, className, placeholder, value, ...rest } = props;
  const [open, setOpen] = useState(false);
  const onOpenDate = (e) => {
    e.stopPropagation();
    if (!rest.disabled) {
      setOpen(true);
    }
  }
  const onDocumentClick = () => {
    setOpen(false);
    console.log("hi");
  }
  useEffect(() => {
    // document.addEventListener('click', onDocumentClick);
    // return () => {
    //   document.removeEventListener('click', onDocumentClick);
    // }
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="Custom input"
        value={value}
        disabled={rest.disabled}
        onChange={(_moment) => {
          console.log(_moment);
          rest.onChange && rest.onChange({ target: { name: rest.name, value: _moment.toDate() } })
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => {
          return (
            <InputContainer className={`date-btn ${className || ''}`} error={rest.error}>
              {
                Boolean(label) && <label>{label}</label>
              }
              <input {...inputProps} id={`${id || ''}`} disabled="true" type='text' ref={inputRef} value={getDateFormatValue(inputProps.value)} placeholder={placeholder || label} />
              {InputProps?.endAdornment}
            </InputContainer>
          )
        }}
      />
    </LocalizationProvider>
  )
}

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  min-height: 100%;
  min-width: 100%;
  background-color: transparent;
`,
);

const StyledListbox = styled('ul')(
  ({ theme, itemProp }) => `
  margin: 10px 0;
  min-width: 100%;
  background: #fff;
  border: 1px solid #707070;
  overflow: auto;
  outline: 0px;
  `,
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  & img {
    margin-right: 10px;
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled disabled={props.disabled || false} {...props} ref={ref} components={components} />;
});
export const UserSelect = (props) => {
  const { users, value } = props;
  return (
    <div className={`row flex-full user-select ${props.className || ''}`}>
      <CustomSelect disabled={props.disabled || false} value={value} className='col' onChange={(selected) => {
        props.onChange && props.onChange({ target: { value: users[selected] } })
      }}>
        {users.map((c, index) => (
          <StyledOption disabled={props.disabled || false} className='h5' key={index} value={index}>
            <AvatarCard small user={c} dark color='#1177CC' className='avatar-select' />
          </StyledOption>
        ))}
      </CustomSelect>
      <i className='h10 icon-chavron-down' />
    </div>

  )
}

export * from "./dropdown";