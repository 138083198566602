import { ReducerRegistry } from "../ReducerRegistry";
import {
  STORE_NAME, SET_MAX_PIN_COUNT, BULK_UPLOAD_TASK, BULK_UPLOAD_TASK_SUCCESS, LIST_ACTIVITY,
  FETCH_DEFINED_LIST_SUCCESS, FETCH_BULK_UPLOAD_SUCCESS, SET_SEARCH_TEXT, SEARCH_TASKS, LIST_CUSTOMTYPES,
  CREATE_TASK_SUCCESS, SET_SORT_MODEL, NOTIF_FREQ_LIST_SUCCESS, DEPT_RESPONSIBLE_SUCCESS, ADD_CUSTOM_TYPE,
  CREATE_TASK, FETCH_TASKS_SUCCESS, FETCH_TASKS, FETCH_TASK, ADD_TASK_COMMENT, ADD_TASK_COMMENT_SUCCESS,
  FETCH_TASK_SUCCESS, UPDATE_TASK_SUCCESS, ATTACH_TASK_EVIDENCE, ATTACH_TASK_EVIDENCE_SUCCESS,
  UPDATE_TASK, FETCH_DASHBOARD_SUCCESS, IS_OBSERV_ADDED
} from "./actionsType";
import { CLEAR_STATE } from "../common";

const DEFAULT_STATE = { tatalCount: 1, commentAdding: false, evidenceAttaching: false, taskFetched: false, sortModel: null };
const getNewTasks = (tasks) => {
  return (tasks || []).map(_task => {
    return { ..._task }
  });
}
ReducerRegistry.register(STORE_NAME, function (state, { type, payload }) {
  if (!state) {
    state = DEFAULT_STATE
  }
  switch (type) {
    case FETCH_DEFINED_LIST_SUCCESS: return { ...state, ...payload }
    case CREATE_TASK: return { ...state, createSuccess: false }
    case CREATE_TASK_SUCCESS: {
      let tasks = getNewTasks(state.tasks);
      tasks.push(payload)
      return { ...state, tasks: tasks, createSuccess: true }
    }
    case UPDATE_TASK: return { ...state, updated: false }
    case FETCH_TASKS:
    case SEARCH_TASKS: return { ...state, tasks: null }
    case FETCH_TASKS_SUCCESS: {
      let tasks, tatalCount;
      if (Array.isArray(payload)) {
        tasks = payload.filter((task) => {
          if (task.tasks_count !== undefined) {
            tatalCount = task.tasks_count;
            return false
          }
          return true;
        })
      } else {
        if (payload.errors) {
          tasks = [];
          tatalCount = 0;
        }
      }
      return { ...state, tasks: tasks, tatalCount: tatalCount };
    }
    case ADD_TASK_COMMENT: return { ...state, commentAdding: true };
    case ATTACH_TASK_EVIDENCE: return { ...state, evidenceAttaching: true };
    case ADD_TASK_COMMENT_SUCCESS: return { ...state, commentAdding: false };
    case ATTACH_TASK_EVIDENCE_SUCCESS: return { ...state, evidenceAttaching: false };
    case UPDATE_TASK_SUCCESS: {
      let tasks = getNewTasks(state.tasks);
      let index = tasks.findIndex(_task => _task.id === payload.id);
      if (index > -1) {
        tasks[index] = { ...tasks[index], ...payload };
      }
      return { ...state, tasks: [...tasks], updated: true };
    }
    case FETCH_TASK_SUCCESS: return { ...state, taskFetched: true }
    case FETCH_TASK: return { ...state, taskFetched: false };
    case FETCH_DASHBOARD_SUCCESS: return { ...state, dashboard: payload }
    case SET_MAX_PIN_COUNT: return { ...state, maxPinCountReached: payload.status }
    case BULK_UPLOAD_TASK: return { ...state, bulkUploading: true }
    case BULK_UPLOAD_TASK_SUCCESS: return { ...state, bulkUploading: false, bulkUploads: [payload, ...(state.bulkUploads || [])] }
    case FETCH_BULK_UPLOAD_SUCCESS: return { ...state, bulkUploads: payload }
    case SET_SEARCH_TEXT: return { ...state, seachText: payload }
    case SET_SORT_MODEL: return { ...state, sortModel: payload }
    case CLEAR_STATE: return { ...DEFAULT_STATE }
    case DEPT_RESPONSIBLE_SUCCESS:
      {
        let responsibles = { ... (state.responsibles || {}) }
        responsibles[payload.orgId] = {...(responsibles[payload.orgId] || {})}
        responsibles[payload.orgId][payload.department] = payload.responsible;
        return { ...state, responsibles: { ...responsibles } }
      }
    case NOTIF_FREQ_LIST_SUCCESS:
      return { ...state, notificationFreq: payload }
    case ADD_CUSTOM_TYPE: {
      let activityTypes = state[LIST_ACTIVITY];
      let customTypes = state[LIST_CUSTOMTYPES];
      let index = activityTypes.concat(customTypes || []).findIndex((_) => _.id === payload);
      customTypes = index === -1 ? [...(customTypes || []), { id: payload, label: payload }] : customTypes;
      return { ...state, [LIST_CUSTOMTYPES]: customTypes }
    }
    case IS_OBSERV_ADDED: return { ...state, observAdded: payload };
    default:
      return state
  }
})