import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { isLoggedIn, login, validateToken } from "../../store";

const LogInScreen = (props) => {
  const navigate = useNavigate();
  const mounted = useRef();

  useEffect(() => {
    let urlObj = new URLSearchParams(window.location.search);
    document.title = "Arc | Login";
    const sessionToken = urlObj.get('sessionToken');
    if (sessionToken) {
      props.validateToken(sessionToken);
    } else {
      navigate('/');
    }
  }, []);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (props.isLoggedIn) {
        let urlObj = new URLSearchParams(window.location.search),
          redirectTo = '/',
          redirectURL = urlObj.get('redirect');
        urlObj.delete('sessionToken');
        urlObj.delete('redirect');
        if (redirectURL) {
          if (redirectURL === 'tasks') {
            redirectTo = '/T?' + urlObj.toString();
          } else {
            redirectTo = '/' + redirectURL;
          }
        }
        navigate(redirectTo);
      }
    }
  });
  return (
    <div className='col flex-ctr full jg-login'>
      <div className='h5'>Please wait...</div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    validateToken: (token) => dispatch(validateToken(token)),
  }
}
const mapStateToProps = (state) => {
  return {
    isLoggedIn: isLoggedIn(state)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LogInScreen);
