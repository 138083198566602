export const STORE_NAME = 'common';

export const SET_ALERT = "SET_ALERT";

export const SET_CONFIRMATION = "SET_CONFIRMATION";

export const SERVER_ERROR = "SERVER_ERROR";

export const LOADING = "LOADING";

export const CLEAR_ALERT = 'CLEAR_ALERT';

export const CLEAR_STATE = 'CLEAR_STATE';

export const ERR_TOKEN = 'ERR_TOKEN';

export const KEY_NM_API = 'NM-API-Key';

export const KEY_NM_GUID = 'KEY_NM_GUID';

export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';