import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "../../components";
import { saveAs } from 'file-saver';
import { axios } from "../../utils/axios";
import { getAllSourceInGroup, fetchDashBoard, getActivityTypesTasks, getAssmentHasTask, isAssessmentLoaded, getTaskPerPage, isResponsible, getCustomTypesTasks, setAlert } from "../../store";
import { Grid, CircularProgress, Card, Table, TableRow, TableBody, TableCell, TableHead, Breadcrumbs, Link } from '@mui/material';
import ARCImg from "../../assets/images/arc.png";
import "./source.scss";

const Columns = [
  { header: '', width: '10%', attribute: 'label' },
  { header: 'Total', width: '15%', attribute: 'total' },
  { header: 'Critical', width: '15%', attribute: 'critical' },
  { header: 'High', width: '15', attribute: 'high' },
  { header: 'Medium', width: '15%', attribute: 'medium' },
  { header: 'Low', width: '15%', attribute: 'low' },
  { header: 'Minimal', width: '15%', attribute: 'minimal' },
  { header: 'Default', width: '15%', attribute: 'default' },
]

const AssessmentLogo = (props) => {
  const [error, setError] = useState(false);
  return (
    <>
      {
        error ?
          <img className='logo' src={ARCImg} alt='logo' />
          :
          <img className='logo' src={`https:${props.src}`} alt='logo' onError={() => setError(true)} />

      }
    </>
  )
}

const SourceTable = (props) => {
  const { rows, overall } = props
  return (
    <Table className='tbl'>
      <TableHead>
        <TableRow>
          {
            Columns.map((column) => {
              const { width, attribute } = column
              return (
                <TableCell
                  width={width}
                  key={attribute}
                  classes={{ root: 'h7 fam-robo-med' }}>
                  <div className='col flex-ctr'>
                    {
                      (attribute === 'label' || attribute === 'total') ?
                        <span>{column.header}</span>
                        :
                        <div className={`priority ${attribute}`}>{column.header}</div>
                    }
                  </div>
                </TableCell>
              )
            })
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((row, i) => {
            return (
              <TableRow key={i} className={``}>
                {
                  Columns.map((column) => {
                    const { attribute, header } = column;
                    const isLabel = attribute === 'label';
                    const value = row[attribute] || row[header];
                    return (
                      <TableCell
                        key={attribute}
                        classes={{ root: 'fam-robo-med' }}>
                        <div className={`col cell ${!isLabel ? 'flex-ctr' : ''}`}>
                          <span className={'h5'}>{value || '-'}</span>
                        </div>
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            )
          })
        }
        <TableRow>
          <TableCell classes={{ root: 'fam-robo-med' }}>
            <div className='col h41'>
              <span>Overall</span>
            </div>
          </TableCell>
          <TableCell classes={{ root: 'fam-robo-med' }}>
            <div className='col flex-ctr  h41'>
              <span>{overall}</span>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const SourceScreen = (props) => {
  const navigate = useNavigate();
  const { sources, activityTypes, customTypes, allSources } = props;
  useEffect(() => {
    document.title = "Arc | Source";
  }, []);
  useEffect(() => {
    if (props.isReady) {
      props.fetchDashBoard()
    }
  }, [props.isReady]);
  const exportTasks = (source) => {
    let body = {};
    if (source.group === "Activity Types" || source.group === "Custom") {
      body['activity_type'] = source.id;
    } else {
      body['asmt_id'] = source.id;
    }
    const per_page = getTaskPerPage()
    const path = `/tasks/task_report?page=1&per_page=${per_page}`;
    axios.post(path, body)
      .then(cont => {
        cont = cont.replaceAll("|", ',');
        var blob = new Blob([cont], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${source.label}_Tasks.csv`)
      })
      .catch((err) => {
        props.setAlert({ severity: 'error', message: "Error While exporting tasks" })
      })
  }
  return (
    <div className='col full sources'>
      <Breadcrumbs aria-label="breadcrumb" classes={{ li: 'h7 exo2 breadcrumb' }}>
        <Link underline="none" classes={{ root: 'link' }} color="#1177CC" href="/S">Source </Link>
        <Link underline="none" classes={{ root: 'link' }} color="#1177CC" href="/S">All</Link>
      </Breadcrumbs>
      {
        !((Boolean(sources) || Boolean(activityTypes)) && props.isReady) ?
          <div className='col flex-ctr full'>
            <CircularProgress />
          </div>
          : (sources || []).concat(activityTypes || []).concat(customTypes).length === 0 ?
            <div className='col flex-ctr full'>
              <div className='h5'>No Sources</div>
            </div>
            :
            <>
              <h1 className='h21 exo2 screen-title'>All Source</h1>
              <Grid container columnSpacing={2} rowSpacing={2}>
                {
                  [...(sources || []), ...(activityTypes || []), ...(customTypes || [])].map((source, index) => {
                    return (
                      <Grid key={index} item lg={6} md={6} sm={12} xs={12}>
                        <Card className='source'>
                          <div className='row '>
                            <AssessmentLogo src={source.logo} />
                            <div className='h4 label exo2'>{source.label}</div>
                          </div>
                          <SourceTable rows={source.rows} overall={source.overall} />
                          <div className='row space-end actions-row'>
                            {
                              (Boolean(source.reporturl) && !props.isResponsible) &&
                              <Button text='View Report' disableElevation={true} color='#1177CC' className='h6' onClick={() => {
                                window.open(source.reporturl, '_blank');
                              }} />
                            }
                            <Button disableElevation={true} text='Export Tasks' color='#1177CC' className='h6' onClick={() => {
                              exportTasks(source)
                            }} />
                            <Button disableElevation={true} text='View Tasks' color='#1177CC' className='h6' onClick={() => {
                              navigate(`/T?asmt_id=${source.asmt && source.asmt.guid ? source.asmt.guid : source.id}`)
                            }} />
                          </div>
                        </Card>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </>
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashBoard: () => dispatch(fetchDashBoard()),
    setAlert: (er) => dispatch(setAlert(er))
  }
}
const mapStateToProps = (state) => {

  return {
    sources: getAssmentHasTask(state),
    isReady: isAssessmentLoaded(state),
    activityTypes: getActivityTypesTasks(state),
    customTypes: getCustomTypesTasks(state),
    isResponsible: isResponsible(state),
    allSources: getAllSourceInGroup(state, true),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SourceScreen);
