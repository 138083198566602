import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { CreateTaskForm } from "./createtask.form";
import { ViewTask } from "./view.task";
import Paper from '@mui/material/Paper';
import { AddButton, LinkButton, IconButton, AutoCompleteSelect, Input, TwoLevelDropDown } from "../../components";
import SearchIcon from '@mui/icons-material/Search';
import TaskTable from "./task.table";
import { axios } from "../../utils/axios";
import { saveAs } from 'file-saver';
import Close from "../../assets/images/close.png";
import {
  getAllTasks, fetchTasks, getProrityList, getAllSourceInGroup, getDepratmentList, setSearchText, getAssigneeList,
  isListReady, searchTasks, getStatusList, fetchTask, isTaskFetched, setPinCountStatus, getPinCountStatus, fetchFilterTasks,
  getSourceWithTaskById, getPriorityById, getAssigneeById, getDepartmentById, getStatusById, fetchDepartmentAuditee, getMappedOrgs,
  getMappedOrgById, getMappedOrgUsers, getUser, setAlert, getTaskPerPage
} from "../../store";
import moment from 'moment';
import "./task.scss";

const FilterAttributes = [
  { key: 'priority', method: 'getPriorityById' },
  { key: 'org', method: 'getMappedOrgById' },
  { key: 'department', method: 'getDepartmentById' },
  { key: 'status', method: 'getStatusById' },
  { key: 'assignee_id', method: 'getAssigneeById' },
  { key: 'asmt_id', method: 'getSourceWithTaskById' },
]
const getDefaultFilterModel = () => {
  let model = {};
  FilterAttributes.forEach(attr => {
    model[attr.key] = '';
  })
  return model
}

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const TaskScreen = (props) => {
  const navigate = useNavigate();
  const first = useRef(true);
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showViewForm, setShowViewForm] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [filter, setFilter] = useState(getDefaultFilterModel());
  const [state, setState] = useState({ showExport: false, pageNo: 1 });
  const taskfetched = usePrevious({ isTaskFetched: props.isTaskFetched })
  useEffect(() => {
    document.title = "Arc | Task";
    if (props.isListReady) {
      let currentFilter = {},
        urlObj = new URLSearchParams(window.location.search),
        searchTxt = urlObj.get('search');
      if (searchTxt && searchTxt.length > 0) {
        setShowSearchFilter(true);
        setSearchText(searchTxt);
        props.searchTasks(1, searchTxt)
      } else {
        FilterAttributes.forEach((attr) => {
          let val = urlObj.get(attr.key);
          if (val && attr.method && props[attr.method]) {
            val = props[attr.method](val)
            currentFilter[attr.key] = val;
          }
        });
        if (Object.keys(currentFilter).length > 0) {
          if (currentFilter.org) {
            props.fetchDepartmentAuditee(currentFilter.department && currentFilter.department.id, currentFilter.org.id, props.user.ent_org_id)
          }
          setFilter((prev) => {
            return { ...prev, ...currentFilter }
          })
        } else {
          onSearch();
        }
      }
    }
  }, []);
  useEffect(() => {
    if (taskfetched && !taskfetched.isTaskFetched && props.isTaskFetched) {
      setShowViewForm(true)
    }
  }, [props.isTaskFetched]);
  useEffect(() => {
    if (first && first.current) {
      first.current = false;
      return
    }
    onSearch();
  }, [filter])
  const onValueChange = (e) => {
    const { name, value } = e.target;
    if (name === 'org' && value) {
      props.fetchDepartmentAuditee(filter.department && filter.department.id, value.id, props.user.ent_org_id)
    } else if (name === 'department' && filter.org && value) {
      props.fetchDepartmentAuditee(value.id, filter.org.id, props.user.ent_org_id)
    }
    setFilter((prev) => {
      return { ...prev, [name]: value }
    });
  }
  const getFilterQueryString = () => {
    let queryString = [], currentFilter = {};
    Object.keys(filter).forEach((key) => {
      if (Boolean(filter[key])) {
        let val = null;
        if (key === 'asmt_id') {
          if (filter[key].group === "Activity Types" || filter[key].group === "Custom") {
            val = filter[key].id;
            currentFilter['activity_type'] = val;
          } else {
            val = filter[key].asmt ? filter[key].asmt.guid : filter[key].id;
            currentFilter[key] = filter[key].id;
          }
        } else {
          val = (filter[key].guid || filter[key].id);
          currentFilter[key] = typeof filter[key] === "object" ? filter[key].id : (filter[key] || '');
        }
        queryString.push(`${key}=${val}`);
      }
    })
    return { queryString, currentFilter }
  }
  const onBulkUpload = () => {
    let { queryString } = getFilterQueryString();
    const path = `/T/bulk-upload-tasks${queryString.length > 0 ? ('?' + queryString.join('&')) : ''}`
    navigate(path);
  }
  const onSearch = () => {
    let { queryString, currentFilter } = getFilterQueryString();
    navigate('/T' + (queryString.length > 0 ? '?' + queryString.join('&') : ''));
    loadTasks(1, currentFilter);
    setState((_) => ({ ..._, showExport: queryString.length > 0 }))
  }
  const loadTasks = (pageNo, iFilter) => {
    if (Object.keys(iFilter).length > 0) {
      props.fetchFilterTasks(pageNo, iFilter)
    } else {
      props.fetchTasks(pageNo)
    }
  }
  const onViewTask = (task) => {
    setSelectedTask(task)
    setShowViewForm(true)
    props.fetchTask(task.id)
    let taskOrg = props.user.ent_org_id;
    if (task.assignee_org_id && task.assignee_org_id !== taskOrg) {
      taskOrg = task.assignee_org_id
    }
    props.fetchDepartmentAuditee(task.department, taskOrg, props.user.ent_org_id)
  }
  const onPageChange = (pageNo) => {
    if (showSearchFilter) {
      let search;
      if (searchText.length > 0) {
        search = searchText;
      } else {
        let urlObj = new URLSearchParams(window.location.search);
        search = urlObj.get('search');
      }
      if (search && search.length > 0) {
        props.searchTasks(pageNo, search);
      }
    } else {
      let { currentFilter } = getFilterQueryString();
      loadTasks(pageNo, currentFilter)
    }
    setState((_) => ({ ..._, pageNo: pageNo }))
  }
  const onSeachAllTask = () => {
    if (searchText.length > 0) {
      navigate('/T?search=' + searchText);
      props.searchTasks(1, searchText)
    }
  }
  const handleFilterTaskExport = () => {
    let { currentFilter } = getFilterQueryString();
    if (Object.keys(currentFilter).length > 0) {
      const per_page = getTaskPerPage()
      const path = `/tasks/task_report?page=${state.pageNo || 1}&per_page=${per_page}`;
      axios.post(path, currentFilter)
        .then(cont => {
          cont = cont.replaceAll("|", ',');
          var blob = new Blob([cont], { type: 'text/csv;charset=utf-8;' });
          saveAs(blob, `Task Export - ${moment().format('ddd, DD-MMM-YY hh:mm a')}.csv`)
        })
        .catch((err) => {
          props.setAlert({ severity: 'error', message: "Error While exporting tasks" })
        })
    }
  }
  return (
    <>
      <h1 className='h21 exo2 screen-title'>Tasks</h1>
      <div className='col task-view'>

        <div className='row filter-container'>
          {
            showSearchFilter ?
              <div className='row filter-row flex-full'>
                <div className='title'>
                  Filters
                </div>
                <div className='row flex-full search-input'>
                  <Input
                    placeholder='Search Task'
                    className='h4 flex-full'
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)} />
                  <IconButton className='btn-search' onClick={onSeachAllTask}>
                    <SearchIcon fontSize='large' sx={{ color: '#999999' }} />
                  </IconButton>
                  <IconButton className='btn-search' onClick={() => {
                    setShowSearchFilter(false)
                    setSearchText('');
                    setFilter((_) => {
                      return { ..._ }
                    })
                  }}>
                    <img src={Close} alt='close' />
                  </IconButton>
                </div>
              </div>
              :
              <div className='row filter-row flex-full'>
                <div className='title'>
                  Filter By
                </div>
                <div className='col flex-full '>
                  <div className='row full search-row'>
                    <div className='col item'>
                      <AutoCompleteSelect
                        name='priority'
                        search
                        radioOption={true}
                        value={filter.priority}
                        options={props.prorityList}
                        onChange={onValueChange}
                        containerClassName='col filter-select'
                        PaperComponent={({ children }) => (
                          <Paper className="paper-content" style={{ background: "yellow", fontSize: "20px" }}>{children}</Paper>
                        )}
                        placeholder='Priority' />
                    </div>
                    <div className='col item'>
                      <AutoCompleteSelect
                        search
                        radioOption={true}
                        name='org'
                        containerClassName='col filter-select'
                        placeholder='Organisation'
                        value={filter.org}
                        options={props.mappedOrgs}
                        onChange={onValueChange}
                      />
                    </div>
                    <div className='col item'>
                      <AutoCompleteSelect
                        search
                        radioOption={true}
                        name='assignee_id'
                        containerClassName='col filter-select'
                        placeholder='People'
                        value={filter.assignee_id}
                        options={filter.org ? props.getMappedOrgUsers(filter.org.id) : props.people}
                        onChange={onValueChange}
                      />
                    </div>
                    <div className='col item'>
                      <AutoCompleteSelect
                        radioOption={true}
                        search
                        containerClassName='col filter-select'
                        placeholder='Department'
                        name='department'
                        value={filter.department}
                        options={props.departmentList}
                        onChange={onValueChange}
                      />
                    </div>
                    <div className='col item'>
                      <AutoCompleteSelect
                        name='status'
                        radioOption={true}
                        options={props.statuses}
                        value={filter.status}
                        containerClassName='col filter-select'
                        placeholder='Status'
                        onChange={onValueChange} />
                    </div>
                    <div className='col item'>
                      <TwoLevelDropDown
                        name='asmt_id'
                        containerClass='col filter-select'
                        placeholder='Source' group={true}
                        value={filter.asmt_id}
                        options={props.sources}
                        onChange={onValueChange} />
                    </div>
                  </div>
                </div>
                <IconButton className='btn-search' onClick={() => setShowSearchFilter(true)}>
                  <SearchIcon fontSize='large' sx={{ color: '#999999' }} />
                </IconButton>
              </div>
          }

          <AddButton className='btn-bulk' text='Bulk Upload Task' onClick={onBulkUpload} />
          <AddButton text='Create Task' onClick={() => {
            setShowCreateForm(true)
          }} />
        </div>
        {
          state.showExport &&
          <div className='row end space-end'>
            <LinkButton label='Export Tasks' className='h6 exo2' onClick={handleFilterTaskExport} />
          </div>
        }
        {
          (showCreateForm || showEditForm) &&
          <CreateTaskForm open isEdit={showEditForm} taskId={selectedTask && selectedTask.id} onCancel={() => {
            setShowCreateForm(false)
            setShowEditForm(false)
          }} />
        }

        <TaskTable tasks={props.tasks} onPageChange={onPageChange} onViewTask={onViewTask} />
        {
          Boolean(showViewForm) &&
          <ViewTask
            open
            taskId={selectedTask.id}
            onEdit={() => {
              setShowViewForm(false);
              setShowEditForm(true)
            }}
            onClose={() => {
              setSelectedTask(null)
              setShowViewForm(false)
            }} />
        }
        {/*
          props.pinMaxReached &&
          <Dialog open className='dialog ' classes={{ paper: 'pin-dialog' }}>
            <div className='col' >
              <strong className='h3 exo2  heading'>Pin limit reached</strong>
              <strong className='h5'>You have already pinned maximum allowed tasks</strong>
              <div className='col flex-ctr action'>
                <Button text='Go Back' color='#1177CC' onClick={() => props.setPinCountStatus(false)} />
              </div>
            </div>
          </Dialog>
      */}
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTasks: (pageNo) => dispatch(fetchTasks(pageNo)),
    fetchFilterTasks: (filter, pageNo) => dispatch(fetchFilterTasks(filter, pageNo)),
    fetchTask: (taskId) => dispatch(fetchTask(taskId)),
    fetchDepartmentAuditee: (dept, orgId, currentUserOrg) => dispatch(fetchDepartmentAuditee(dept, orgId, currentUserOrg)),
    setPinCountStatus: (value) => dispatch(setPinCountStatus(value)),
    setSearchText: (text) => dispatch(setSearchText(text)),
    searchTasks: (pageNo, text) => dispatch(searchTasks(pageNo, text)),
    setAlert: (er) => dispatch(setAlert(er))
  }
}
const mapStateToProps = (state) => {
  return {
    user: getUser(state),
    tasks: getAllTasks(state, true),
    prorityList: getProrityList(state),
    departmentList: getDepratmentList(state),
    mappedOrgs: getMappedOrgs(state),
    sources: getAllSourceInGroup(state, true),
    people: getAssigneeList(state),
    isListReady: isListReady(state),
    statuses: getStatusList(state),
    isTaskFetched: isTaskFetched(state),
    pinMaxReached: getPinCountStatus(state),
    getPriorityById: (id) => getPriorityById(state, id),
    getDepartmentById: (id) => getDepartmentById(state, id),
    getStatusById: (id) => getStatusById(state, id),
    getAssigneeById: (id) => getAssigneeById(state, id),
    getSourceWithTaskById: (id) => getSourceWithTaskById(state, id),
    getMappedOrgById: (id) => getMappedOrgById(state, id),
    getMappedOrgUsers: (id) => getMappedOrgUsers(state, id)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskScreen);
export { default as TaskHomeScreen } from "./home";
export { default as BulkUploadScreen } from "./bulk-upload";
