import { SagaRegistry } from "../SagaRegistry";
import { requestHandler, setAlertAndLoading, CLEAR_ALERT } from "../common";
import { takeEvery, put } from 'redux-saga/effects';
import {
  LOGIN, LOGIN_SUCCESS, LOGOUT, VALIDATE_TOKEN_SUCCESS, LOGOUT_SUCCESS
} from "./actionsType";
import { axios } from "../../utils";
import { KEY_NM_API, KEY_NM_GUID, VALIDATE_TOKEN } from "../common";

const USER_PATH = '';

function* logInUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const body = { api: true, ent_usr: payload };
    const res = yield axios.post(USER_PATH + '/login', body);
    if (res) {
      if (res.current_ent_usr) {
        localStorage.setItem(KEY_NM_API, res.current_ent_usr.api_key)
        if (res.ent_org_logo) {
          res.current_ent_usr.logo_url = res.ent_org_logo;
        }
      }
    }
    yield setAlertAndLoading(false, { message: 'Login Successfull' });
    yield put({ type: LOGIN_SUCCESS, payload: res })
  })
}
function* validateToken({ payload }) {
  yield requestHandler(function* () {
    try {
      let token = payload && payload.token;
      if (!token) {
        token = localStorage.getItem(KEY_NM_API);
      }
      let user = 'Error'
      if (token) {
        const header = { [KEY_NM_API]: token };
        const res = yield axios.post('/tasks/api_user_detail', { task: { api_key: token } }, header);
        user = res.errors ? 'Error' : res
      }
      if (user !== 'Error' && token) {
        localStorage.setItem(KEY_NM_API, token)
      }
      yield put({ type: VALIDATE_TOKEN_SUCCESS, payload: user })
    } catch (error) {
      localStorage.setItem(KEY_NM_API, null)
      localStorage.setItem(KEY_NM_GUID, null)
      yield put({ type: VALIDATE_TOKEN_SUCCESS, payload: 'Error' })
      throw error;
    }
  })
}
function* logout() {
  yield requestHandler(function* () {
    localStorage.setItem(KEY_NM_API, null)
    localStorage.setItem(KEY_NM_GUID, null)
    yield setAlertAndLoading(false, { message: 'Logged out Successfully' });
    yield put({ type: LOGOUT_SUCCESS });
    yield put({ type: CLEAR_ALERT });
  })
}
SagaRegistry.register(function* () {
  yield takeEvery(LOGIN, logInUser)
  yield takeEvery(VALIDATE_TOKEN, validateToken)
  yield takeEvery(LOGOUT, logout)
})